<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card>
      <v-card-title class="justify-center pt-12 mb-2 text-uppercase">{{
        $t("send_email")
      }}</v-card-title>
      <v-form @submit.prevent="sendEmail()">
        <v-card-text>
          <v-row v-if="!done">
            <v-col cols="12" md="12">
              <div
                class="px-2 py-1"
                style="border: 1px solid #aaa; border-radius: 5px;"
              >
                <candidate-chip :candidate="candidate"></candidate-chip>
              </div>
            </v-col>
            <v-col cols="12" md="12" class="pb-0">
              <v-text-field
                v-model="subject"
                :label="$t('subject')"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="mt-0 pt-0">
              <tiptap-vuetify
                v-model="body"
                :extensions="extensions"
                :placeholder="$t('insert_body_email')"
                style="max-width: 100%"
              />
            </v-col>
          </v-row>
          <v-row
            class="d-flex flex-row justify-end align-center mt-7 mb-2 px-3"
          >
            <v-btn
              :color="!show ? '' : primary"
              :text="!show"
              class="mx-2"
              @click="$emit('close')"
              style="background: #fff; color:#FAA638;"
              >{{ show || done ? $t("close") : $t("Cancel") }}</v-btn
            >
            <v-btn
              v-if="!show && !done"
              :disabled="isLoading"
              color="primary"
              type="submit"
              >{{ $t("send") }}</v-btn
            >
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatError } from "@/utils";
import { mapGetters } from "vuex";
import { ADD_DIALOG } from "@/mixins/dialog";
import CandidateChip from "@/modules/entity/components/CandidateChip.vue";

import { SEND_EMAIL_TO_CANDIDATE } from "../../../modules/candidate/graphql/Mutation";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";

export default {
  name: "SendEmailToCandidate",
  components: {
    CandidateChip,
    TiptapVuetify,
  },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    candidate: Object,
    show: Boolean,
  },
  data: () => ({
    body: "",
    subject: "",
    done: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    async sendEmail() {
      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: SEND_EMAIL_TO_CANDIDATE,
          variables: {
            sendEmailToCandidateInput: {
              candidateEmail: this.candidate.email,
              subject: this.subject,
              entityName: this.currentUser.entity.name,
              entityEmail: this.currentUser.entity.email,
              recruiterEmail: this.currentUser.email,
              message: this.body,
            },
          },
        });
        this.done = true;
        this.$emit("done");
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
