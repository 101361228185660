export default class Interview {
  constructor(
    id,
    address,
    candidates,
    date,
    message,
    observers,
    recruters,
    subject,
    telephone,
    time
  ) {
    this.id = id;
    this.address = address;
    this.candidates = candidates;
    this.date = date;
    this.message = message;
    this.observers = observers;
    this.recruters = recruters;
    this.subject = subject;
    this.telephone = telephone;
    this.time = time;
  }
}
