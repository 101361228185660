<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-title class="justify-center">{{
        $t("schedule_interview")
      }}</v-card-title>
      <v-card-text
        ><span class="mx-4">{{ $t("to") }}:</span>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="send()"
        >
          <v-row class="mt-4 mb-2 align-center px-6" style="margin-top: -3%">
            <template v-for="candidate in selectedCandidates">
              <candidate-chip :candidate="candidate" :key="candidate.id" />
            </template>
          </v-row>
          <v-divider class="my-2"></v-divider>

          <v-row align="center">
            <!-- <span class="mx-4 py-6 px-3" style="margin-bottom:-7%">Recrutadores:</span> -->
            <!-- <v-col cols="12" md="12" class="px-6"> -->
            <v-col cols="12" md="12">
              <template>
                <div style="margin-top: -1%">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on" class="mx-0">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div
                      v-html="$t('tooltip_scheduleInterview_recruter')"
                    ></div>
                  </v-tooltip>
                </div>
              </template>
              <v-select
                v-model="interview.recruters"
                :items="filteredRecruters"
                :label="$t('recruiters')"
                chips
                item-text="name"
                item-value="id"
                multiple
                return-object
                dense
                outlined
                hide-details
                required
                :rules="requiredRules"
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    small
                    @click="data.select"
                    @click:close="removeRecruter(data.item)"
                  >
                    <v-avatar left size="24">
                      <v-img
                        :src="
                          data.item.photo
                            ? `${apiUrl}/images/user/${data.item.photo}`
                            : `/avatar.png`
                        "
                      ></v-img>
                    </v-avatar>
                    {{ data.item.name.split(" ")[0] }}
                    {{
                      data.item.name.split(" ")[
                        data.item.name.split(" ").length - 1
                      ]
                    }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar size="24">
                      <img
                        :src="
                          data.item.photo
                            ? `${apiUrl}/images/user/${data.item.photo}`
                            : `/avatar.png`
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content v-if="data.item.name">
                      <v-list-item-title
                        v-html="
                          `${data.item.name.split(' ')[0]} ${
                            data.item.name.split(' ')[
                              data.item.name.split(' ').length - 1
                            ]
                          }`
                        "
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.office"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <!-- <v-col cols="12" md="12" class="px-6"> -->
            <v-col cols="12" md="12">
              <template>
                <div style="margin-top: -4%">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on" class="mx-0">
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <div v-html="$t('tooltip_scheduleInterview_guest')"></div>
                  </v-tooltip>
                </div>
              </template>

              <v-combobox
                v-model="interview.observers"
                :items="filteredGuests"
                :label="$t('Guests')"
                chips
                item-text="name"
                item-value="id"
                multiple
                return-object
                dense
                outlined
                hide-details
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    small
                    @click="data.select"
                    @click:close="removeGuest(data.item)"
                  >
                    <v-avatar left size="24">
                      <v-img
                        :src="
                          data.item.photo
                            ? `${apiUrl}/images/user/${data.item.photo}`
                            : `/avatar.png`
                        "
                      ></v-img>
                    </v-avatar>
                    {{ data.item.name.split(" ")[0] }}
                    {{
                      data.item.name.split(" ")[
                        data.item.name.split(" ").length - 1
                      ]
                    }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-avatar size="24">
                      <img
                        :src="
                          data.item.photo
                            ? `${apiUrl}/images/user/${data.item.photo}`
                            : `/avatar.png`
                        "
                      />
                    </v-list-item-avatar>
                    <v-list-item-content v-if="data.item.name">
                      <v-list-item-title
                        v-html="
                          `${data.item.name.split(' ')[0]} ${
                            data.item.name.split(' ')[
                              data.item.name.split(' ').length - 1
                            ]
                          }`
                        "
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.office"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-combobox>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="interview.subject"
                :label="$t('subject')"
                dense
                outlined
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="interview.date"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="interview.date"
                    :label="$t('interview_date')"
                    prepend-inner-icon="event"
                    readonly
                    v-on="on"
                    dense
                    outlined
                    hide-details
                    :rules="requiredRules"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="interview.date"
                  no-title
                  scrollable
                  :min="dateFormat()"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">{{
                    $t("cancel")
                  }}</v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(interview.date)"
                    >OK</v-btn
                  >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="interview.time"
                :label="$t('interview_time')"
                v-mask="'##:##'"
                placeholder="00:00"
                prepend-inner-icon="access_time"
                dense
                outlined
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="interview.address"
                :label="$t('phisic_adress')"
                dense
                prepend-inner-icon="place"
                outlined
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="interview.telephone"
                :label="$t('Institution_telephone')"
                dense
                prepend-inner-icon="mdi-phone"
                outlined
                hide-details
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea
                v-model="interview.message"
                rows="3"
                :label="$t('enter_message')"
                :hint="$t('interviewCreateSuccesshint')"
                persistent-hint
                dense
                outlined
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row
            class="d-flex flex-row justify-end align-center mt-2 px-3 py-6"
          >
            <v-btn text class="mx-2" @click="$emit('close')" color="primary">{{
              $t("cancel")
            }}</v-btn>
            <v-btn :disabled="isLoading" color="primary" type="submit" dense>{{
              $t("send")
            }}</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { SCHEDULE_INTERVIEW_MUTATION } from "../graphql/Mutation.resolver";
import { GET_USERS_ENTITY_QUERY } from "../graphql/Query.resolver";
import { formatErrors } from "@/utils";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import moment from "moment";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import CandidateChip from "../../../components/CandidateChip.vue";
import Interview from "@/models/Interview";
export default {
  name: "ScheduleInterviewDialog",
  components: { ErrorDialog, ProgressDialog, SuccessDialog, CandidateChip },
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    selectedCandidates: Array,
    vacancyId: String,
    client: String,
  },
  data: () => ({
    apiUrl: API_URL,
    interview: new Interview(),
    menu: false,
    menu2: false,
    usersLocal: [],
    valid: true,
  }),
  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
      fetchPolicy: "no-cache",
    },
  },
  watch: {
    dialog() {
      this.interview = new Interview();
    },
  },
  computed: {
    filteredRecruters() {
      let resultItems = this.users;
      if (this.interview.observers && this.interview.observers.length > 0) {
        resultItems = resultItems.filter((recruter) => {
          const index = this.interview.observers.findIndex(
            (guest) => guest.id == recruter.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
    filteredGuests() {
      let resultItems = this.users;
      if (this.interview.recruters && this.interview.recruters.length > 0) {
        resultItems = resultItems.filter((observer) => {
          const index = this.interview.recruters.findIndex(
            (recruter) => recruter.id == observer.id
          );
          return index === -1;
        });
      }

      return resultItems;
    },
    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },
  methods: {
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    removeGuest(item) {
      const index = this.interview.observers.indexOf(item);
      if (index >= 0) this.interview.observers.splice(index, 1);
    },
    removeRecruter(item) {
      const index = this.interview.recruters.indexOf(item);
      if (index >= 0) this.interview.recruters.splice(index, 1);
    },
    async send() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        this.interview.candidates = this.selectedCandidates.map((c) => c.id);
        this.interview.recruters = this.interview.recruters.map(({ id }) => id);
        this.interview.observers = this.interview.observers
          ? this.interview.observers.map(({ id }) => id)
          : undefined;
        this.interview.vacancy = this.vacancyId;
        this.interview.client = this.client;
        try {
          await this.$apollo.mutate({
            mutation: SCHEDULE_INTERVIEW_MUTATION,
            variables: {
              interviewInput: this.interview,
            },
          });
          this.success = this.$t("intervistaSucesso");
          this.showSuccess = true;
          this.$emit("close");
        } catch (error) {
          console.log(error);
          // console.log({ format: error.message.split(":")[3].split(",")[0] });

          this.error = formatErrors(error.message);

          this.interview.recruters = undefined;
          this.interview.observers = undefined;
          this.showError = true;
        } finally {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
