import gql from "graphql-tag";
import candidateFragment from "./CandidateFragment";

export const ADD_CANDIDATE_TO_LIST_MUTATION = gql`
  mutation AddCandidateToList($id: ID!, $candidateId: ID!) {
    addCandidateToList(id: $id, candidateId: $candidateId) {
      id
      candidates
      name
    }
  }
`;

export const CREATE_CANDIDATE_ENTITY_MUTATION = gql`
  mutation CreateCandidateEntity($candidateInput: CandidateInput, $fromCandidate: Boolean) {
    createCandidateEntity(candidateInput: $candidateInput, fromCandidate: $fromCandidate) {
      ...${candidateFragment}
    }
  }
`;

export const UPDATE_CANDIDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateEntityCandidateAccount($candidateInput: CandidateInput) {
    updateEntityCandidateAccount(candidateInput: $candidateInput) {
      ...${candidateFragment}
    }
  }
`;

export const CREATE_CANDIDATE_LIST_MUTATION = gql`
  mutation CreateCandidateList($candidateId: ID!, $name: String!) {
    createCandidateList(candidateId: $candidateId, name: $name) {
      id
      candidates
      name
    }
  }
`;

export const CREATE_INPORTED_CANDIDATES_MUTATION = gql`
  mutation CreateImportedCandidates($candidates: [CandidateInput], $fromCandidate: Boolean) {
    createImportedCandidates(candidates: $candidates, fromCandidate: $fromCandidate) {
      ...${candidateFragment}
    }
  }
`;

export const CREATE_PRIVATE_VACANCY_MUTATION = gql`
  mutation CreatePrivateVacancy($vacancyInput: VacancyInput) {
    createPrivateVacancy(vacancyInput: $vacancyInput) {
      id
    }
  }
`;

export const DELETE_CANDIDATE_LIST_MUTATION = gql`
  mutation DeleteCandidateList($id: ID!) {
    deleteCandidateList(id: $id) {
      id
    }
  }
`;

export const IMPORT_CANDIDATES_MUTATION = gql`
  mutation ImportCandidates($candidates: Upload) {
    importCandidates(candidates: $candidates) {
      email
      name
      newCreated
      lastName
      telephone
    }
  }
`;

export const IMPORT_CANDIDATE_FROM_CURRICULUM_MUTATION = gql`
  mutation ImportCandidateFromCurriculum($curriculums: [Upload!]) {
    importCandidateFromCurriculum(curriculums: $curriculums) {
      certificates
      email
      name
      newCreated
      lastName
      telephone
    }
  }
`;

export const REMOVE_CANDIDATE_FROM_LIST_MUTATION = gql`
  mutation RemoveCandidateFromList($id: ID!, $candidateId: ID!) {
    removeCandidateFromList(id: $id, candidateId: $candidateId) {
      id
      candidates
      name
    }
  }
`;

export const SEND_VACANCY_MUTATION = gql`
  mutation SendVacancy(
    $candidates: [CandidateInput]
    $vacancyId: ID!
    $specialDate: String
  ) {
    sendVacancy(
      candidates: $candidates
      vacancyId: $vacancyId
      specialDate: $specialDate
    ) {
      id
    }
  }
`;

export const ADD_NOTE_CANDIDATE_MUTATION = gql`
  mutation addCandidateNote($candidateId: ID!, $note: String!, $time: String) {
    addCandidateNote(candidateId: $candidateId, note: $note, time: $time) {
      name
      text
      time
      user {
        id
      }
    }
  }
`;
export const DELETE_NOTE_CANDIDATE_MUTATION = gql`
  mutation deleteCandidateNote($candidateId: ID!, $time: String) {
    deleteCandidateNote(candidateId: $candidateId, time: $time) {
      time
    }
  }
`;
export const REMOVE_CERTIFICATE_MUTATION = gql`
  mutation RemoveCertificate($index: Int!, $candidateId: ID) {
    removeCertificate(index: $index, candidateId: $candidateId)
  }
`;
export const UPLOAD_CENTIFICATE_MUTATION = gql`
  mutation UploadCertificate($certificate: Upload!, $candidateId: ID) {
    uploadCertificate(certificate: $certificate, candidateId: $candidateId)
  }
`;

export const SEND_EMAIL_TO_CANDIDATE = gql`
  mutation SendEmailToCandidate(
    $sendEmailToCandidateInput: SendEmailToCandidateInput!
  ) {
    sendEmailToCandidate(sendEmailToCandidateInput: $sendEmailToCandidateInput)
  }
`;
