<template>
  <div class="vue-html2pdf">
    <section
      class="layout-container"
      :class="{
        'show-layout': showLayout,
        'unset-all': !floatLayout,
      }"
    >
      <section
        class="content-wrapper"
        :style="`width: ${pdfContentWidth};`"
        ref="pdfContent"
      >
        <slot name="pdf-content" />
      </section>
    </section>

    <transition name="transition-anim">
      <v-row justify="center" v-if="!numPages">
        <v-col class="text-subtitle-1 text-center" cols="12">
          {{ $t("loadinCV") }}
        </v-col>
        <v-col cols="12"  justify="center" class="text-center">
          <v-progress-circular indeterminate color="primary" :size="50"></v-progress-circular>
        </v-col>
      </v-row>
      <section class="pdf-preview" v-if="numPages" style="background: #525659">
        <pdf
          ref="myPdfComponent"
          v-for="i in numPages"
          :key="i"
          :src="path"
          :page="i"
          style="
            border: 0px solid #525659;
            margin-bottom: 2%;
            background: #525659;
            border-radius: 5px;
          "
        ></pdf>
      </section>
    </transition>
  </div>
</template>
<script>
import html2pdf from "html2pdf.js";
import pdf from "vue-pdf";
import { API_URL } from "@/api";
// var loadingTask = pdf.createLoadingTask(`${this.pdfFile}`);
export default {
  components: { pdf },
  props: {
    localeCV:{
      type: String,
    },
    showLayout: {
      type: Boolean,
      default: false,
    },

    floatLayout: {
      type: Boolean,
      default: true,
    },

    enableDownload: {
      type: Boolean,
      default: true,
    },

    previewModal: {
      type: Boolean,
      default: false,
    },

    paginateElementsByHeight: {
      type: Number,
    },

    filename: {
      type: String,
      default: `${new Date().getTime()}`,
    },

    pdfQuality: {
      type: Number,
      default: 4,
    },

    pdfFormat: {
      default: "a4",
    },

    pdfOrientation: {
      type: String,
      default: "portrait",
    },

    pdfContentWidth: {
      default: "800px",
    },

    htmlToPdfOptions: {
      type: Object,
    },

    manualPagination: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hasAlreadyParsed: false,
      progress: 0,
      pdfWindow: null,
      pdfFile: null,
      currentPage: 0,
      pageCount: 0,
      numPages: 0,
      url: API_URL,
      path: "",
    };
  },

  watch: {
    progress(val) {
      this.$emit("progress", val);
    },

    paginateElementsByHeight() {
      this.resetPagination();
    },

    $props: {
      handler() {
        this.validateProps();
      },

      deep: true,
      immediate: true,
    },
  },

  methods: {
    validateProps() {
      // If manual-pagination is false, paginate-elements-by-height props is required
      if (!this.manualPagination) {
        if (this.paginateElementsByHeight === undefined) {
          console.error(
            "Error: paginate-elements-by-height is required if manual-pagination is false"
          );
        }
      }
    },
    onProgress() {
      this.$emit("progress", this.numPages);
    },
    resetPagination() {
      const parentElement = this.$refs.pdfContent.firstChild;
      const pageBreaks = parentElement.getElementsByClassName(
        "html2pdf__page-break"
      );
      const pageBreakLength = pageBreaks.length - 1;

      if (pageBreakLength === -1) return;

      this.hasAlreadyParsed = false;

      // Remove All Page Break (For Pagination)
      for (let x = pageBreakLength; x >= 0; x--) {
        pageBreaks[x].parentNode.removeChild(pageBreaks[x]);
      }
    },

    generatePdf() {
      this.$emit("startPagination");
      this.progress = 0;
      this.paginationOfElements();
    },

    paginationOfElements() {
      this.progress = 25;

      /*
				When this props is true, 
				the props paginate-elements-by-height will not be used.
				Instead the pagination process will rely on the elements with a class "html2pdf__page-break"
				to know where to page break, which is automatically done by html2pdf.js
			*/
      if (this.manualPagination) {
        this.progress = 70;
        this.$emit("hasPaginated");
        this.downloadPdf();
        return;
      }

      if (!this.hasAlreadyParsed) {
        const parentElement = this.$refs.pdfContent.firstChild;
        const ArrOfContentChildren = Array.from(parentElement.children);
        let childrenHeight = 0;

        /*
					Loop through Elements and add there height with childrenHeight variable.
					Once the childrenHeight is >= this.paginateElementsByHeight, create a div with
					a class named 'html2pdf__page-break' and insert the element before the element
					that will be in the next page
				*/
        for (const childElement of ArrOfContentChildren) {
          // Get The First Class of the element
          const elementFirstClass = childElement.classList[0];
          const isPageBreakClass = elementFirstClass === "html2pdf__page-break";
          if (isPageBreakClass) {
            childrenHeight = 0;
          } else {
            // Get Element Height
            const elementHeight = childElement.clientHeight;

            // Get Computed Margin Top and Bottom
            const elementComputedStyle =
              childElement.currentStyle ||
              window.getComputedStyle(childElement);
            const elementMarginTopBottom =
              parseInt(elementComputedStyle.marginTop) +
              parseInt(elementComputedStyle.marginBottom);

            // Add Both Element Height with the Elements Margin Top and Bottom
            const elementHeightWithMargin =
              elementHeight + elementMarginTopBottom;

            if (
              childrenHeight + elementHeight <
              this.paginateElementsByHeight
            ) {
              childrenHeight += elementHeightWithMargin;
            } else {
              const section = document.createElement("div");
              section.classList.add("html2pdf__page-break");
              parentElement.insertBefore(section, childElement);

              // Reset Variables made the upper condition false
              childrenHeight = elementHeightWithMargin;
            }
          }
        }

        this.progress = 70;

        /*
					Set to true so that if would generate again we wouldn't need
					to parse the HTML to paginate the elements
				*/
        this.hasAlreadyParsed = true;
      } else {
        this.progress = 70;
      }

      this.$emit("hasPaginated");
      this.downloadPdf();
    },

    async downloadPdf() {
      // Set Element and Html2pdf.js Options
      const pdfContent = this.$refs.pdfContent;
      let options = this.setOptions();

      this.$emit("beforeDownload", { html2pdf, options, pdfContent });

      const html2PdfSetup = html2pdf().set(options).from(pdfContent);
      let pdfBlobUrl = null;

      if (this.previewModal) {
        this.pdfFile = await html2PdfSetup.output("bloburl");
        pdfBlobUrl = this.pdfFile;
      }

      if (this.enableDownload) {
        pdfBlobUrl = await html2PdfSetup.save().output("bloburl");
      }

      if (pdfBlobUrl) {
        const res = await fetch(pdfBlobUrl);
        const blobFile = await res.blob();
        this.$emit("hasDownloaded", blobFile);
      }

      this.progress = 100;
    },

    setOptions() {
      if (
        this.htmlToPdfOptions !== undefined &&
        this.htmlToPdfOptions !== null
      ) {
        return this.htmlToPdfOptions;
      }

      return {
        margin: 0,

        filename: `${this.filename}.pdf`,

        image: {
          type: "jpeg",
          quality: 0.98,
        },

        enableLinks: false,

        html2canvas: {
          scale: this.pdfQuality,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: this.pdfFormat,
          orientation: this.pdfOrientation,
        },
      };
    },

    closePreview() {
      this.pdfFile = null;
    },
    getPages() {
      // var i=0;
      this.$refs.myPdfComponent.pdf.forEachPage(function (page) {
        return page;
      });
    },
  },
  created() {
    this.path = this.url + `/pdf/cv/${this.localeCV?this.localeCV:this.$i18n.locale}/${this.filename}/report.pdf`;
    pdf
      .createLoadingTask(this.path)
      .promise.then((pdf) => {
        this.numPages = pdf.numPages;
        this.onProgress();
        console.log("paginas", this.numPages);
        console.log("this.path", this.path);
      })
      .catch((err) => {
        console.log("Messangem:", err.message);
      });
  },
};
</script>

<style lang="scss" scoped>
.vue-html2pdf {
  .layout-container {
    position: fixed;
    width: 1000vw;
    height: 100vh;
    left: -100vw;
    // top: 0;
    z-index: -1;
    background: rgba(255, 255, 255, 0);
    // display: flex;
    // justify-content: center;
    // align-items: flex-start;
    overflow: hidden;

    // &.show-layout {
    // 	left: 0vw;
    // 	z-index: 9999;
    // }

    // &.unset-all {
    // 	all: unset;
    // 	width: auto;
    // 	height: auto;
    // }
  }

  .pdf-preview {
    // position: fixed;
    // width: 65%;
    // min-width: 600px;
    // height: 100%;
    // top: 100px;
    // z-index: 9999999;
    // left: 50%;
    // transform: translateX(-50%);
    border-radius: 5px;
    margin-bottom: 600px;
    box-shadow: 0px 0px 10px #00000048;

    button {
      position: absolute;
      top: -20px;
      left: -15px;
      width: 305px;
      height: 35px;
      background: rgb(172, 47, 47);
      border: 0;
      box-shadow: 0px 0px 0px #525659;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      cursor: pointer;
    }

    iframe {
      border: 0;
      height: 100%;
      width: 100%;
      box-shadow: 0px 0px 0px #525659;
    }
  }

  .transition-anim-enter-active,
  .transition-anim-leave-active {
    transition: opacity 0.3s ease-in;
  }

  .transition-anim-enter,
  .transition-anim-leave-to {
    opacity: 0;
  }
  iframe::-webkit-scrollbar {
    display: none;
  }
}
</style>
