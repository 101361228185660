<template>
  <div>
    <div class="d-flex justify-center">
      <div class="black--text text-center" v-html="$t('selectTestSend')"></div>
      <template>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" small class="mx-3"> mdi-help-circle-outline</v-icon>
            </template>
            <div v-html="$t('tooltip_selecttest_candidate')"></div>
          </v-tooltip>
        </template>
    </div>
    
    <v-row class="mx-4 mt-0 scroll-test">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
        v-for="item in items"
        :key="item.id"
      >
        <slot name="item" :item="item" v-if="item.user"/>
      </v-col>
      <div
        v-if="items.length"
        v-observe-visibility="handleScrolledToBottom"
      ></div>
    </v-row>
    
  </div>
</template>

<script>
export default {
  name: "InfiniteScroll",
  props: {
    items: {
      required: true,
      type: Array,
    },
    totalPages: Number,
  },
  data: () => ({
    page: 1,
    expand: false,
  }),
  watch: {
    totalPages() {
      this.page = 1;
    },
  },
  methods: {
    handleScrolledToBottom(isVisible) {
      if (!isVisible) {
        return;
      }

      this.page++;
      this.$emit("refetch", this.page);
    },
  },
};
</script>

<style scoped>
.scroll-test {
  height: 350px;
  overflow: auto;
}
</style>
