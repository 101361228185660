<template>
  <v-hover v-slot:default="{ hover }" open-delay="200">
    <v-card :elevation="hover ? 16 : 2" max-width="480" max-height="480">
      <div style="height: 150px">
        <v-img
          lazy-src="https://picsum.photos/id/11/10/6"
          :src="
            test.image ? `${apiUrl}/images/test/${test.image}` : `/teste.jpeg`
          "
          height="150px"
        >
          <v-system-bar window flat color="rgba(0, 0, 0, 0)" dense>
            <v-menu v-if="test.entity === currentUser.entity.id" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark icon v-bind="attrs" v-on="on">
                  <v-icon color="primary">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list dense>
                <v-list-item
                  @click.prevent="$emit('edit', Object.assign({}, test))"
                >
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$emit('delete', test.id)">
                  <v-list-item-title>Remover</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <strong
              v-if="test.entity && test.creatorType != 'entity' && test.status"
              class="success--text"
              >Aprovado</strong
            >
            <strong
              v-else-if="test.entity && test.creatorType != 'entity'"
              class="error--text"
              >Pendente</strong
            >

            <v-spacer></v-spacer>

            <v-chip dark small label class="text-capitalize">
              {{ $t(test.type) }}
            </v-chip>
          </v-system-bar>
        </v-img>
      </div>

      <v-card-text>
        <!-- <pre>
          {{test}}
        </pre> -->
        <!-- <div class="text-capitalize" style="font-size: 12px">{{ test.type }}</div> -->
        <!-- <pre>{{test.title.length}}</pre> -->
        <div
          v-if="test.title.length >= 36"
          class="body black--text"
          style="font-size: 12pt"
        >
          {{ test.title }}
        </div>

        <div v-else class="body black--text" style="font-size: 12pt">
          {{ test.title }}
        </div>

        <!-- <div
            class="my-2 px-0"
            style="font-size: 12px"
          >
            Teste elaborado por {{ getUserName(test.user.name) }}
          </div> -->

        <p
          v-if="test.description.length > 45"
          class="black--text py-1"
          style="font-size: 14px; height: 50px"
        >
          {{
            test.description ? test.description.substring(0, 45) + "..." : ""
          }}
        </p>
        <p v-else class="black--text" style="font-size: 14px; height: 50px">
          {{ test.description ? test.description : "" }}
        </p>
        <v-row no-gutters>
          <v-col cols="12" md="12">
            <!-- {{ isOpenAnswer(test) }} -->
            <span class="black--text">{{ $t("Multiplechoice") }}:</span>
            <v-progress-linear
              v-if="test.result || test.result == 0"
              :value="test.result"
              :color="setColor(test.result)"
              height="16"
              readonly
              class="mb-6"
            >
              <span class="text--white">{{ test.result }}%</span>
            </v-progress-linear>
            <v-progress-linear
              v-else-if="test.type !== 'psychological'"
              :value="0"
              :color="days(test) ? 'cyan' : 'error'"
              height="16"
              readonly
              class="mb-6"
            >
              <span class="caption">{{
                days(test) ? $t("pending") : $t("expired")
              }}</span>
            </v-progress-linear>
            <v-progress-linear
              v-else
              :value="0"
              :color="days(test) ? 'cyan' : 'error'"
              height="16"
              readonly
              class="mb-6"
            >
              <span class="caption">{{
                days(test) ? $t("pending") : $t("expired")
              }}</span>
            </v-progress-linear>
          </v-col>
          <v-col cols="12" md="12">
            <span class="black--text">{{ $t("openanswer") }}:</span>
            <v-card tile flat>
              <!-- <v-chip color="orange" small label outlined dense pill> -->
              <span class="caption" v-if="isOpenAnswer(test)">
                <v-progress-linear
                  :value="textObsResult"
                  :color="setColor(textObsResult)"
                  height="16"
                  readonly
                  class="mb-6"
                >
                  <span class="caption">{{ textObsResult }}%</span>
                </v-progress-linear>
              </span>
              <span class="caption" v-else>{{ $t("notdefined") }} </span>
              <!-- </v-chip> -->
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions style="margin-top: -25px">
        <v-spacer></v-spacer>
        <v-btn color="primary" text small @click="$emit('preview', test)">
          {{ $t("view") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "TestCard",
  data: () => ({
    apiUrl: API_URL,
  }),
  props: {
    test: Object,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    textObsResult() {
      let sum = 0;
      this.test.questions.map((q) => {
        if (q.observations) {
          q.observations.map((obs) => {
            if (obs.result) sum += parseFloat(obs.result);
          });
          if (parseInt(q.observations.length)) {
            sum = parseFloat(sum) / parseInt(q.observations.length);
          }
        }
      });

      return sum;
    },
  },
  methods: {
    getUserName(name) {
      name = name.split(" ");
      return name[0];
    },
    calculePontuation(tests) {
      let total = tests.length;
      return (
        tests.reduce((accumulator, item) => {
          console.log(accumulator, item);

          if (item.status) {
            return accumulator + item.result;
          } else {
            total = total - 1;
            return accumulator + item.result;
          }
        }, 0) / total
      );
    },
    setColor(punctuation) {
      if (punctuation < 50) {
        return "red";
      } else if (punctuation < 70) {
        return "#FFC107";
      } else {
        return "green";
      }
    },
    isOpenAnswer(test) {
      let oq = undefined;
      test.questions.map((q) => {
        if (q.openQuestion) {
          oq = true;
        }
      });
      return oq;
    },
    days(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
  },
};
</script>
