import gql from "graphql-tag";
import candidateFragment from "./CandidateFragment.gql";
import moduleFragment from "./ModuleFragment.gql";
import userFragment from "./UserFragment.gql";
import testPayFagment from "./TestPayFragment";
import vacancyFragment from "./VacancyFragment";

export const GET_CANDIDATE_BY_ID_QUERY = gql`
  query GetCandidateById($id: ID!) {
    candidate: getCandidateById(id: $id) {
      ...${candidateFragment}
    }
  }
`;

export const USER_QUERY = gql`
  query User {
    user {
      ...${userFragment}
    }
  }
`;
export const CANDIDATE_SKILL = gql`
  query getCandidatesSkill {
    Skills: getCandidatesSkill
  }
`;
export const SHARED_VACANCIES = gql`
  query getSharedVacacny($id:ID!) {
    getSharedVacacny(id :$id){
      ...${vacancyFragment}
    }
  }
`;

export const GET_TRANSACTIONS_BY_ENTITY = gql`
  query GetTransactionsByEntity {
    transactions: getTransactionsByEntity {
      id
      amountPaid
      code
      date
      module {
        id
        name
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
      }
      status
      totalProfiles
      totalUsers
      type
      value
    }
  }
`;

export const GET_TRANSACTIONS_BY_ENTITY_ID = gql`
  query GetTransactionsByEntityId($entityId: ID!) {
    transactions: getTransactionsByEntityId(entityId: $entityId) {
      id
      module {
        id
        name
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
      }
      totalProfiles
      totalUsers
    }
  }
`;

export const PLANS_QUERY = gql`
  query Plans {
    plans: plans {
      id
      name
      duration
    }
  }
`;

export const MODULES_QUERY = gql`
  query Modules {
    modules: modules {
      ...${moduleFragment}
    }
  }
`;

export const GET_TESTS_PAYMENT = gql`
  query getPaymentTestsEntity {
    payment: getPaymentTestsEntity {
      id
      cod
      tests{
        test{...${testPayFagment}}
        paid
        toPay
        toConfirmed
        status
        partner
      }
      candidates{
        id
      }
      entity{
        id
        name
      }
      vacancy{
        id 
        title
      }
      status
      amount
      date
      time
      createdAt
      updatedAt
    }
  }
`;

export const GET_BLOCKED_CANDIDATES_QUERY = gql`
  query GetBlockedCandidates {
    blockedCandidates: getBlockedCandidates
  }
`;

export const GET_TRAINING_AREAS = gql`
  query getTrainngAreas {
    getTrainingAreas {
      id
      text {
        en
        pt
      }
      key
      entity
    }
  }
`;
