<template>
  <v-container class="fill-heigt pt-8" fluid>
    <v-row class="mt-5 px-3">
      <!-- <pre>:>>{{ candidate_id }}</pre> -->
      <!-- <pre>{{ this.vacancy_Id.candidacies }}</pre> -->
      <!-- <pre>{{ getTests }}</pre> -->
      <!-- {{ candidate_id }} -->
      <v-spacer></v-spacer>
      <div v-if="!flat">
        <v-btn icon color="primary" @click="routerBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </div>
      <div
        v-if="$route.query.from === 'ranking' && !flat"
        class="d-flex flex-row align-center"
      >
        <div class="text--disabled">{{ $t("assign_rating") }}:</div>
        <div class="text-center mr-2">
          <v-rating
            v-model="rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            half-increments
            hover
            small
            @input="setRating()"
          ></v-rating>
        </div>
      </div>
      <div v-else>
        <template v-if="!flat">
          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-chip
                v-on="on"
                link
                label
                outlined
                color="primary"
                class="mx-2"
                @click="dialogNote = true"
                >{{ $t("Notepad") }}</v-chip
              >
            </template>
            <div v-html="$t('tooltip_notpad')"></div>
          </v-tooltip>
        </template>

        <template v-if="from != 'ranking'">
          <v-tooltip left>
            <!-- <pre>{{ list }}</pre> -->
            <template v-slot:activator="{ on }">
              <!-- .... {{ candidateId }} -->
              <v-btn
                v-on="on"
                color="primary"
                dark
                @click="dialogList = true"
                class="px-6"
              >
                <v-icon small>mdi-plus</v-icon>{{ $t("AddtoFavorites") }}
              </v-btn>
            </template>
            <div v-html="$t('tooltip_favlist_candidate')"></div>
          </v-tooltip>
        </template>
      </div>
    </v-row>
    <v-row class="mt-7">
      <v-col cols="12" md="9" v-if="getCandidate">
        <profile-details
          :candidate="getCandidate"
          :filter="filter"
          :vacancyId="$route.query.vacancyId"
          :vacancies="vacancies"
          :vacancy="vacancy"
        />
      </v-col>
      <v-col cols="12" md="3" class="pt-0">
        <!-- <pre>
          {{ valueTLanguage()/3 }}
          {{ valueTLanguage()/2 }}
          {{ valueTLanguage()/1 }}
        </pre> -->

        <div
          v-if="
            $route.query.from != 'ranking' &&
              valueTLanguage() &&
              vacancy.weight &&
              valueTFormation() &&
              valueTProfessionalExperience()
          "
        >
          <div class="mb-2 text-uppercase">
            {{ $t("Matching_with_the_vacancy") }}
          </div>
          <v-divider class="py-3"></v-divider>
          <v-sheet color="white" elevation="2" width="100%">
            <!-- <v-row class="d-flex flex-row justify-end align-center ml-3 mb-3"> -->
            <div class="justify-center align-center ml-3 pb-1">
              <div class="mb-3 pt-3" v-if="valueTLanguage()">
                {{ $t("languages") }}
                <v-progress-linear
                  :color="
                    getLanguageVacancyPerc(candidate, vacancy) * half <=
                    valueTLanguage() / 3
                      ? 'red'
                      : getLanguageVacancyPerc(candidate, vacancy) * half <=
                        valueTLanguage() / 2
                      ? 'primary'
                      : 'success'
                  "
                  height="10"
                  :value="getLanguageVacancyPerc(candidate, vacancy) * half"
                  style="max-width: 300px; min-width: 225px"
                  :buffer-value="valueTLanguage()"
                  ><span class="caption"
                    >{{
                      DFormatter(
                        getLanguageVacancyPerc(candidate, vacancy) * half
                      )
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <!-- <pre>candidate:{{ candidate.areasInterest }}</pre>
              <pre>vacancy:{{ vacancy.areaInterest }}</pre> -->
              <div class="mb-3" v-if="vacancy.weight">
                {{ $t("Related_areas") }}
                <v-progress-linear
                  :color="
                    getAreaCandVacancyPer(candidate, vacancy) * half <=
                    vacancy.weight / 3
                      ? 'red'
                      : getAreaCandVacancyPer(candidate, vacancy) * half <=
                        vacancy.weight / 2
                      ? 'primary'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getAreaCandVacancyPer(candidate, vacancy) * half"
                  :buffer-value="vacancy.weight"
                  ><span class="caption"
                    >{{
                      DFormatter(
                        getAreaCandVacancyPer(candidate, vacancy) * half
                      )
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-3" v-if="valueTFormation()">
                {{ $t("form_candidate_academic_level") }}
                <v-progress-linear
                  :color="
                    getFormationVacancyPerc(candidate, vacancy) * half <=
                    valueTFormation() / 3
                      ? 'red'
                      : getFormationVacancyPerc(candidate, vacancy) * half <=
                        valueTFormation() / 2
                      ? 'primary'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getFormationVacancyPerc(candidate, vacancy) * half"
                  :buffer-value="valueTFormation()"
                  ><span class="caption"
                    >{{
                      DFormatter(
                        getFormationVacancyPerc(candidate, vacancy) * half
                      )
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-3" v-if="valueTProfessionalExperience()">
                {{ $t("form_candidate_experience_label") }}
                <v-progress-linear
                  :color="
                    getExpCandVacancyPer(candidate, vacancy) * half <=
                    valueTProfessionalExperience() / 3
                      ? 'red'
                      : getExpCandVacancyPer(candidate, vacancy) * half <=
                        valueTProfessionalExperience() / 2
                      ? 'primary'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="getExpCandVacancyPer(candidate, vacancy) * half"
                  :buffer-value="valueTProfessionalExperience()"
                  ><span class="caption"
                    >{{
                      DFormatter(
                        getExpCandVacancyPer(candidate, vacancy) * half
                      )
                    }}%</span
                  ></v-progress-linear
                >
              </div>
              <div class="mb-6" v-if="getTests.length">
                {{ $t("test") }}
                <v-progress-linear
                  :color="
                    calculePontuation(getTests) <= (half * 100) / 3
                      ? 'red'
                      : calculePontuation(getTests) <= (half * 100) / 2
                      ? 'primary'
                      : 'success'
                  "
                  height="10"
                  style="max-width: 300px; min-width: 225px"
                  :value="calculePontuation(getTests)"
                  :buffer-value="half * 100"
                  ><span class="caption"
                    >{{ DFormatter(calculePontuation(getTests)) }}%</span
                  ></v-progress-linear
                >
              </div>
            </div>
            <!-- </v-row> -->
          </v-sheet>
        </div>

        <div class="mb-2 text-uppercase mt-3">
          {{ $t("recruitment_stages") }}

          <v-tooltip left>
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" class="ml-2" color="primary">
                mdi-information-variant-circle-outline
              </v-icon>
            </template>
            <div
              style="width: 180px"
              v-html="$t('See_recruitment_stages_scores')"
            ></div>
          </v-tooltip>
        </div>
        <v-divider class="py-3"></v-divider>
        <!-- <pre>{{ vacancy.stages }}</pre> -->
        <v-card v-if="vacancy">
          <v-card-text>
            <v-simple-table dense fixed-header>
              <template v-slot:default>
                <tbody>
                  <tr v-for="stage in getCandidancy.stages" :key="stage.name">
                    <td class="black--text">
                      <strong class="body-2 font-weight-bold black--text "
                        >{{ stage.name }}

                        <v-tooltip
                          left
                          v-show="
                            !(
                              stage.name ===
                              $t('default_stage_name', vacancy.locale)
                            )
                          "
                        >
                          <template v-slot:activator="{ on }">
                            <v-icon
                              small
                              v-on="on"
                              class="ml-2 mr-2"
                              color="primary"
                              v-show="
                                !(
                                  stage.name ===
                                  $t('default_stage_name', vacancy.locale)
                                )
                              "
                            >
                              mdi-information-variant-circle-outline
                            </v-icon>
                          </template>
                          <div
                            style="width: 180px"
                            v-html="stage.analyze"
                          ></div> </v-tooltip
                      ></strong>
                      <v-list-item
                        two-line
                        style="padding-left: 0; margin-top: -5%;"
                        v-show="
                          !(
                            stage.name ===
                            $t('default_stage_name', vacancy.locale)
                          )
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title class=""> </v-list-item-title>
                          {{ $t("start_date_time") }}:
                          <v-list-item-subtitle
                            class="body-2 font-weight-regular"
                          >
                            {{ dateFormat(stage.startDate) }}
                            {{ $t("at_") }}
                            {{ stage.startTime }}
                          </v-list-item-subtitle>
                          {{ $t("end_date_time") }}:
                          <v-list-item-subtitle
                            class="body-2 font-weight-regular"
                          >
                            {{ dateFormat(stage.endDate) }}
                            {{ $t("at_") }}
                            {{ stage.endTime }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <P
                        style="margin-top: -2.5%;"
                        v-show="
                          !(
                            stage.name ===
                            $t('default_stage_name', vacancy.locale)
                          )
                        "
                        >{{ $t("participants") }}:
                        <div>
                          <avatar-group :items="stage.participants" />
                        </div>
                      </P>
                      <v-list-item
                        two-line
                        style="padding-left: 0; margin-top: -13%;"
                        v-show="
                          !(
                            stage.name ===
                            $t('default_stage_name', vacancy.locale)
                          )
                        "
                      >
                        <v-list-item-content>
                          <v-list-item-title class=""> </v-list-item-title>
                          {{ $t("candidate_status") }}:
                          <v-list-item-subtitle
                            class="body-2 font-weight-regular"
                          >
                            <v-chip
                              small
                              color="primary"
                              v-show="stage.status === null"
                              class="text-center"
                              dark
                              label
                              v-if="
                                !getCandidancy.stages.find(
                                  (s) => s.status === 'reprove'
                                )
                              "
                            >
                              {{ $t("in_progress") }}
                            </v-chip>
                            <v-chip
                              small
                              :color="
                                stage.status === 'aprove' ? 'success' : 'error'
                              "
                              v-show="stage.status !== null"
                              class="text-center"
                              dark
                              label
                            >
                              {{ $t(stage.status) }}
                            </v-chip>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </td>
                  </tr>
                </tbody>
                <!-- <div
                  v-for="(stage, index) in vacancy.candidacies[
                    getCandidacyIndex
                  ].stages"
                  :key="stage.name"
                  class="d-flex align-center"
                >
                  <div style="width: 100%">{{ stage.name }}</div>
                  <div style="width: 50%">
                    <v-text-field
                      dense
                      :disabled="!isStageChange"
                      prefix="%"
                      v-model.number="stage.rating"
                    ></v-text-field>
                  </div>
                  <div @click="changeStage(index, stage.rating)">
                    <v-icon small v-show="!isStageChange">mdi-pencil</v-icon>
                    <v-icon small v-show="isStageChange">mdi-play</v-icon>
                  </div>
                </div> -->
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <note-candidate-dialog
      :candidateId="getCandidate"
      :dialog="dialogNote"
      :ranking="false"
      :vacancyId="vacancy"
      @close="dialogNote = false"
    />
    <add-candidate-list-dialog
      v-if="getCandidate"
      @updateList="$emit('updateList')"
      :candidateId="getCandidate.id"
      :list="list"
      :dialog1="dialogList"
      :vacancyId="vacancy.id"
      @close="dialogList = false"
    />
    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
  </v-container>
</template>

<script>
import { API_URL } from "@/api";
import {
  UPDATE_CANDIDATE_RATING_MUTATION,
  UPDATE_STAGE_RATING_MUTATION,
} from "./../graphql/Mutation.resolver";
import {
  GET_CANDIDATE_BY_ID_QUERY,
  // GET_CANDIDATES_ENTITY_QUERY,
} from "./../../candidate/graphql/Query";
import moment from "moment";
import { ADD_DIALOG } from "@/mixins/dialog";
import matchCandidate from "@/mixins/matchCandidate";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import NoteCandidateDialog from "./../components/NoteCandidateDialog";
import ProfileDetails from "../components/ProfileDetails.vue";
import AddCandidateListDialog from "../components/AddCandidateListDialog.vue";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
// import { GET_VACANCY_BY_ID_QUERY } from "../../../../candidate/modules/dashboard/graphql/Query";
import { GET_VACANCY_BY_ID_QUERY } from "../graphql/Query.resolver";
import AvatarGroup from "../../interview/components/recruter/AvatarGroup.vue";
export default {
  name: "Profile",
  components: {
    NoteCandidateDialog,
    ProfileDetails,
    AddCandidateListDialog,
    ErrorDialog,
    ProgressDialog,
    AvatarGroup,
  },
  mixins: [ADD_DIALOG, matchCandidate],
  props: ["candidate_id", "vacancy_Id", "flat", "from", "list", "vacancies"],
  apollo: {
    candidate: {
      query: GET_CANDIDATE_BY_ID_QUERY,
      variables() {
        return {
          id: this.candidateId ? this.candidateId : this.candidate_id,
        };
      },
      skip() {
        return this.skipQueryC;
      },
      result({ data, loading }) {
        if (!loading) {
          this.candidate = data.candidate;
          const index = this.candidate.ratings.findIndex(
            (r) => r.entity == this.currentUser.entity.id
          );
          this.rating = index > -1 ? this.candidate.ratings[index].value : 0;
        }
      },
    },
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.$route.query.vacancyId
            ? this.$route.query.vacancyId
            : this.vacancy_Id.id,
          fromProfile: true,
        };
      },
      // fetchPolicy: "no-cache",
      result({ data, loading }) {
        if (!loading) {
          this.vacancy = data.vacancy;
        }
      },
    },
    // candidates: {
    //   query: GET_CANDIDATES_ENTITY_QUERY,
    //   variables() {
    //     return { filterCandidate: this.filter };
    //   },
    //   skip() {
    //     return this.skipQueryCacndidates;
    //   },
    //   result({ data, loading }) {
    //     console.log("data.candidates.candidatess", data.candidates.candidates);
    //     if (!loading) {
    //       this.candidates = data.candidates.candidates.filter(
    //         (c) => c.id != this.candidateId
    //       );
    //     }
    //   },
    // },
  },
  watch: {
    vacancy: function(val) {
      if (val)
        if (this.getCandidancy.tests.filter((t) => t.stage).length == 0) {
          this.half = 1;
        } else {
          this.half = 0.5;
        }
    },
  },
  data: () => ({
    apiUrl: API_URL,
    candidateId: undefined,
    isStageChange: false,
    stageRating: null,
    candidate: {},
    candidates: [],
    half: 0.5,
    dialogList: false,
    dialogNote: false,
    filter: {
      areasInterest: undefined,
      belongsToMany: undefined,
      favorites: undefined,
      formation: [
        {
          course: undefined,
          level: undefined,
        },
      ],
      languages: [
        {
          name: undefined,
          level: undefined,
        },
      ],
      ids: undefined,
      limit: 100,
      maxYear: undefined,
      minYear: undefined,
      maxSalary: undefined,
      minSalary: undefined,
      page: 1,
      professionalExperience: undefined,
    },
    rating: 0,
    skipQueryC: true,
    skipQueryCacndidates: true,
    skipQuery: true,
    vacancy: {},
    vacancyId: undefined,
  }),
  created() {
    this.start();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      // getCandidate: 'candidate/getCandidate'
    }),
    getCandidate() {
      return this.candidate;
    },
    getTests() {
      if (this.vacancy.candidacies) {
        return this.vacancy.candidacies
          .find((c) => c.candidate && c.candidate.id === this.candidate_id)
          .tests.filter((t) => t.stage);
      }
      return [];
    },
    getCandidancy() {
      return this.vacancy_Id.candidacies
        ? this.vacancy_Id.candidacies.find(
            (c) =>
              (c.candidate && c.candidate === this.candidate_id) ||
              (typeof c.candidate.id !== "undefined" &&
                c.candidate.id === this.candidate_id)
          )
        : null;
    },
    getCandidacyIndex() {
      return this.vacancy_Id.candidacies.findIndex((candidacy) =>
        candidacy.candidate && candidacy.candidate === this.candidateId
          ? this.candidateId
          : this.candidate_id
      );
    },
  },
  methods: {
    ...mapActions({}),
    dateFormat(date) {
      if (date) {
        return moment(date.substring(0, 10))
          .lang(this.$i18n.locale)
          .format("LL");
      }
    },
    async changeStage(stageIndex, rating) {
      if (this.isStageChange) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_STAGE_RATING_MUTATION,
            variables: {
              id: this.vacancy_Id.id,
              stageIndex,
              candidateIndex: this.getCandidacyIndex,
              rating: rating ? rating : 0,
            },
          });
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isStageChange = false;
        }
      } else {
        this.isStageChange = true;
      }
    },

    start() {
      this.candidateId = this.$route.params.id;
      this.vacancyId = this.$route.query.vacancyId;
      this.filter = this.$route.query.filter
        ? { ...this.$route.query.filter }
        : this.filter;
      this.filter.limit = 100;
      this.fetchCandidate();
      // this.fetchCandidates();
    },
    async fetchCandidate() {
      this.$apollo.queries.candidate.skip = false;
      await this.$apollo.queries.candidate.refetch();
    },
    async fetchCandidates() {
      this.$apollo.queries.candidates.skip = false;
      await this.$apollo.queries.candidates.refetch();
    },
    go(candidateId) {
      this.candidateId = candidateId;
      var index = this.candidates.findIndex((i) => i.id === this.candidateId);
      console.log(index);
      if (index > -1) {
        this.candidates.splice(index, 1);
      }
      this.fetchCandidate();
      //this.fetchCandidates();
    },
    valueTLanguage() {
      let total = 0;
      if (this.vacancy.languages) {
        this.vacancy.languages.forEach((element) => {
          total += element.weight;
        });
      }
      return total;
    },
    valueTProfessionalExperience() {
      let total = 0;
      this.vacancy.professionalExperience.forEach((element) => {
        total += element.weight;
      });
      return total;
    },
    valueTFormation() {
      let total = 0;
      this.vacancy.formation.forEach((element) => {
        total += element.weight;
      });
      return total;
    },
    DFormatter(num) {
      let mtnLocale = Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
      }).format(num);
      return mtnLocale;
    },
    routerBack() {
      switch (this.$route.query.from) {
        case "candidacies":
          this.$router.push({
            path: "/dashboard/entity/candidacies",
            query: { filter: this.filter, vacancyId: this.vacancyId },
          });
          break;

        case "ranking":
          this.$router.push({
            path: "/dashboard/entity/ranking",
            query: { filter: this.filter, vacancyId: this.vacancyId },
          });
          break;

        default:
          this.$router.push({
            path: "/dashboard/entity/candidates",
            query: { filter: this.filter },
          });
          break;
      }
    },
    async setRating() {
      try {
        this.isLoading = true;
        await this.$apollo.mutate({
          mutation: UPDATE_CANDIDATE_RATING_MUTATION,
          variables: {
            id: this.candidate.id,
            rating: this.rating ? this.rating : 0,
          },
        });
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    calculePontuation(tests) {
      let total = tests.length;
      let result =
        tests.reduce((accumulator, item) => {
          if (!accumulator) {
            accumulator = 0;
          }
          if (!item.result) {
            item.result = 0;
          }
          if (item.type == "psychological" || item.type == "technician") {
            console.log(item.type, item.questions);
            let cal = item.questions.find((q) => q.openQuestion === true);
            if (cal) {
              // console.log(cal);
              let totaScoreObsrvation = cal.observations.length
                ? cal.observations
                    .map((obs) => {
                      return obs.result;
                    })
                    .map(Number)
                    .reduce((acc, curr) => acc + curr, 0)
                : 0;
              if (cal.observations.length) {
                totaScoreObsrvation =
                  totaScoreObsrvation / cal.observations.length;
              }

              console.log(
                parseFloat(totaScoreObsrvation),
                parseFloat(item.result),
                parseFloat(accumulator)
              );

              return (
                parseFloat(accumulator) +
                parseFloat(item.result) +
                parseFloat(totaScoreObsrvation)
              );
            } else {
              // total = total - 1;
              console.log("tecn", parseFloat(accumulator));
              return parseFloat(accumulator) + parseFloat(item.result);
            }
          } else if (item.result) {
            return accumulator + item.result;
          } else if (!this.days_(item)) {
            return accumulator + 0;
          } else {
            total = total - 1;
            return accumulator + 0;
          }
        }, 0) / total;
      result = result * this.half;
      return result >= 0 ? Number(result.toFixed(2)) : 0;
    },
    days_(test) {
      if (test.status) {
        return { status: "Concluido", color: "success" };
      }

      const testDate = test.date + " " + test.time;
      const finalDate = moment(testDate, "YYYY-MM-DD H:mm").locale("pt");
      const now = moment().format("YYYY-MM-DD H:mm");

      return now < finalDate._i;
    },
  },
};
</script>
<style scoped>
.list {
  height: 500px;
  overflow-y: auto;
}

::-webkit-scrollbar-thumb:vertical {
  height: 1px;
  background-color: #fff;
}

::-webkit-scrollbar-thumb:horizontal {
  height: 1px;
  background-color: #fff;
}
</style>
