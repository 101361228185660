<template>
  <v-dialog v-model="dialog" persistent max-width="500px">
    <v-card class="pa-10">
      <v-row class="d-flex flex-column">
        <div>
          <v-text-field
            v-model="newEmail"
            label="E-mail"
            outlined
            dense
          ></v-text-field>
        </div>
        <div class="text-right">
          <v-btn text small color="primary" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            small
            color="primary"
            :loading="isLoading"
            @click="updateEmail()"
          >
            {{ $t("send") }}
          </v-btn>
        </div>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { EDIT_EMAIL_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
export default {
  name: "EditEmailDialog",

  data: () => ({
    dialog: false,
    newEmail: "",
    currentEmail: "",
    interviewId: null,
    isLoading: false,
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("EditEmailDialog", ({ interviewId, candidateEmail }) => {
      this.interviewId = interviewId;
      this.currentEmail = candidateEmail;
      this.dialog = true;
    });
  },

  methods: {
    async updateEmail() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: EDIT_EMAIL_INTERVIEW_MUTATION,
          variables: {
            interviewEditEmailinput: {
              interviewId: this.interviewId,
              candidateEmail: this.currentEmail,
              newEmail: this.newEmail,
            },
          },
        });
        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
