import gql from "graphql-tag";
import testFagment from "./TestFragment";

export const ENTITIES_QUERY = gql`
  query Entities {
    entities: entities {
      id
      name
    }
  }
`;

export const GET_TESTS_ENTITY_QUERY = gql`
  query GetTestsEntity($filterTest: FilterTest) {
    tests: getTestsEntity(filterTest: $filterTest) {
      tests {
        ...${testFagment}
      }
      totalDocs
      totalPages
    }
  }
`;

export const GET_VACANCIES_ENTITY_QUERY = gql`
  query GetVacanciesEntity($private: Boolean) {
    vacancies: getVacanciesEntity(private: $private) {
      id
      title
    }
  }
`;

export const GET_VACANCIES_ENTITY_ALL_QUERY = gql`
  query GetVacanciesEntityAll {
    vacancies: getVacanciesEntityAll {
      id
      title
    }
  }
`;
