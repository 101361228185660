import yearsMixins from "@/mixins/years";

export default {
  mixins: [
    yearsMixins,
  ],
  data: () => ({
    levelLanguage: [{
      text: "Básico",
      key: "basic"
    },
    {
      text: "Intermédio",
      key: "intermediate"
    },
    {
      text: "Avançado",
      key: "advanced"
    },
    ],
    levelAcademic: [{
      key: "basic",
      text: "Básico"
    },
    {
      key: "elementary",
      text: "Elementar"
    },
    {
      key: "dedium",
      text: "Médio"
    },
    {
      key: "bachelor",
      text: "Bacharelato"
    },
    {
      key: "graduation",
      text: "Licenciatura"
    },
    {
      key: "master_degree",
      text: "Mestrado"
    },
    {
      key: "doctorate",
      text: "Doutoramento"
    },
    {
      key: "Phd",
      text: "Phd"
    },
    ],
  }),
  methods: {
    getLanguageVacancyPerc(candidate, vacancy) {
      let total = 0;
      let weight = 0;
      if (vacancy.languages) {
        let languageVacancy = vacancy.languages.filter((l) => {
          return l;
        });
        for (let index = 0; index < languageVacancy.length; index++) {
          weight = 0
          if (languageVacancy[index].name === 'Português' || languageVacancy[index].name === 'Portuguese') {
            candidate.languages.forEach(l => {
              if (l.name === 'portuguese') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }

              }
            });
          }
          if (languageVacancy[index].name === 'Inglês' || languageVacancy[index].name === 'English') {

            candidate.languages.forEach(l => {
              if (l.name === 'english') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }

              }
            });
          }
          if (languageVacancy[index].name === 'Françês' || languageVacancy[index].name === 'French') {

            candidate.languages.forEach(l => {
              if (l.name === 'french') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
          if (languageVacancy[index].name === 'Alemão' || languageVacancy[index].name === 'German') {
            candidate.languages.forEach(l => {
              if (l.name === 'german') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
          if (languageVacancy[index].name === 'Espanhol' || languageVacancy[index].name === 'Spanish') {

            candidate.languages.forEach(l => {
              if (l.name === 'spanish') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

                    
          if (languageVacancy[index].name === 'Barwe') {

            candidate.languages.forEach(l => {
              if (l.name === 'barwe') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Chewa') {

            candidate.languages.forEach(l => {
              if (l.name === 'chewa') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Chiyao') {

            candidate.languages.forEach(l => {
              if (l.name === 'chiyao') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Chopi') {

            candidate.languages.forEach(l => {
              if (l.name === 'chopi') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Chuwabu') {

            candidate.languages.forEach(l => {
              if (l.name === 'chuwabu') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Dema') {

            candidate.languages.forEach(l => {
              if (l.name === 'dema') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Kokola') {

            candidate.languages.forEach(l => {
              if (l.name === 'kokola') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Koti') {

            candidate.languages.forEach(l => {
              if (l.name === 'koti') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Kunda') {

            candidate.languages.forEach(l => {
              if (l.name === 'kunda') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Lolo') {

            candidate.languages.forEach(l => {
              if (l.name === 'lolo') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Lomwe') {

            candidate.languages.forEach(l => {
              if (l.name === 'lomwe') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Maindo') {

            candidate.languages.forEach(l => {
              if (l.name === 'maindo') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Makhuwa') {

            candidate.languages.forEach(l => {
              if (l.name === 'makhuwa') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Makonde') {

            candidate.languages.forEach(l => {
              if (l.name === 'makonde') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Makwe') {

            candidate.languages.forEach(l => {
              if (l.name === 'makwe') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Manyawa') {

            candidate.languages.forEach(l => {
              if (l.name === 'manyawa') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Marenje') {

            candidate.languages.forEach(l => {
              if (l.name === 'marenje') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Mwani') {

            candidate.languages.forEach(l => {
              if (l.name === 'mwani') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Nathembo') {

            candidate.languages.forEach(l => {
              if (l.name === 'nathembo') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Ndau') {

            candidate.languages.forEach(l => {
              if (l.name === 'ndau') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Ngoni') {

            candidate.languages.forEach(l => {
              if (l.name === 'ngoni') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }
          
          if (languageVacancy[index].name === 'Nsenga') {

            candidate.languages.forEach(l => {
              if (l.name === 'nsenga') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Nyungwe') {

            candidate.languages.forEach(l => {
              if (l.name === 'nyungwe') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Phimbi') {

            candidate.languages.forEach(l => {
              if (l.name === 'phimbi') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Ronga') {

            candidate.languages.forEach(l => {
              if (l.name === 'ronga') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Sena') {

            candidate.languages.forEach(l => {
              if (l.name === 'sena') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Shona') {

            candidate.languages.forEach(l => {
              if (l.name === 'shona') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Swahili') {

            candidate.languages.forEach(l => {
              if (l.name === 'swahili') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Swati') {

            candidate.languages.forEach(l => {
              if (l.name === 'swati') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Takwane') {

            candidate.languages.forEach(l => {
              if (l.name === 'takwane') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Tawara') {

            candidate.languages.forEach(l => {
              if (l.name === 'tawara') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Tewe') {

            candidate.languages.forEach(l => {
              if (l.name === 'tewe') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Tonga') {

            candidate.languages.forEach(l => {
              if (l.name === 'tonga') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Tsonga') {

            candidate.languages.forEach(l => {
              if (l.name === 'tsonga') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Tswa') {

            candidate.languages.forEach(l => {
              if (l.name === 'tswa') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

          if (languageVacancy[index].name === 'Zulu') {

            candidate.languages.forEach(l => {
              if (l.name === 'zulu') {
                weight = languageVacancy[index].weight / 2;
                const candlangLevel = this.levelLanguage.findIndex(i => i.key === l.level) + 1
                const vaclangLevel = this.levelLanguage.findIndex(i => i.key === languageVacancy[index].level) + 1
                if (((candlangLevel * weight) / vaclangLevel) >= weight) {
                  total += languageVacancy[index].weight
                } else {
                  total += weight + (candlangLevel * weight) / vaclangLevel
                }
              }
            });
          }

        }

      }
      // console.log(weight);
      return total
    },
    getFormationVacancyPerc(candidate, vacancy) {

      let total = 0;
      let weight = 0;
      if (vacancy.formation) {
        let formationVacancy = vacancy.formation.filter((l) => {
          return l;
        });

        for (let index = 0; index < formationVacancy.length; index++) {
          weight = 0;
          let formation = candidate.formation.filter(
            (form) => form.course === formationVacancy[index].course
          );
          if (formation.length > 0) {
            weight = formationVacancy[index].weight / 2;
            const candFormLevel = this.levelAcademic.findIndex(i => i.key === formation[0].level) + 1
            const vacFormLevel = this.levelAcademic.findIndex(i => i.key === formationVacancy[index].level) + 1
            if (((candFormLevel * weight) / vacFormLevel) >= weight) {
              total += formationVacancy[index].weight
            } else {
              total += weight + (candFormLevel * weight) / vacFormLevel
            }
          }
        }
      }
      // console.log(weight);
      return total;
    },
    getAreaCandVacancyPer(candidate, vacancy) {
      let total = 0;
      let result;
      if (vacancy.areaInterest) {
        for (let index = 0; index < vacancy.areaInterest.length; index++) {
          result = candidate.areasInterest.filter(
            (exp) => exp === vacancy.areaInterest[index]
          );
          if (result.length) {
            total = vacancy.weight;
          }
        }
      }
      // console.log(result);
      return total;
    },
    getExpCandVacancyPer(candidate, vacancy) {
      let experience = 0;
      let yearsExperience = 0;
      let candidateExpYears = this.days(candidate.professionalExperience);
      let candidate_index_sec = candidate.professionalExperience.findIndex(cs => cs.sector === vacancy.professionalExperience[0].sector)

      if (candidate_index_sec > -1) {
        // console.log("before", candidate.professionalExperience);
        // console.log("found", [candidate.professionalExperience[candidate_index_sec]]);
        candidateExpYears = this.days([candidate.professionalExperience[candidate_index_sec]]);
      }
      

      let weight = 0;
      if (vacancy.professionalExperience) {
        if (vacancy.professionalExperience[0]) {
          weight = vacancy.professionalExperience[0].sector ? vacancy.professionalExperience[0].weight / 2 : vacancy.professionalExperience[0].weight
          if (vacancy.professionalExperience[0].yearsExperience != 0) {

            yearsExperience =
              (candidateExpYears * weight) /
              vacancy.professionalExperience[0].yearsExperience;
              // console.log("candidateExpYears",candidateExpYears);
          } else {
            if (candidateExpYears === vacancy.professionalExperience[0].yearsExperience) {
              yearsExperience = weight;
            } else {
              yearsExperience = candidateExpYears;
            }
            if (vacancy.professionalExperience[0].yearsExperience === 0) {
              // experience = 0
              yearsExperience = weight
            }
          }
          if (yearsExperience >= weight) {
            experience = weight
          } else {
            experience = yearsExperience;
          }
        }
      } else {
        experience = 0
      }
      if (vacancy.professionalExperience.length) {
        // console.log("vacancy.professionalExperience.length",vacancy.professionalExperience);
        if (vacancy.professionalExperience[0].sector) {
          let candidate_index_sector = candidate.professionalExperience.findIndex(cs => cs.sector === vacancy.professionalExperience[0].sector)
          // console.log(candidate.email,candidate.professionalExperience);
          if (candidate_index_sector > -1) {
            const resulExp = experience+weight
            if (resulExp > weight) {
              experience += weight
            }else{
              experience += weight ///20.83 contabilista
            }
            
          }else{
            experience = 0
          }
        }
      }

      // console.log(yearsExperience, candidateExpYears, experience);
      return experience;
    },
  }
}