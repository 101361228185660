<template>
  <v-dialog v-model="dialog" persistent max-width="550">
    <v-card class="px-3">
      <v-card-title class="justify-center">
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <v-spacer></v-spacer>
          <v-sheet class="d-flex" color="grey lighten-3">
            <span class="pa-2 body-2 text--black"
              >{{ $t("total_pay") }}: <b>{{ getTotalPrice() }}</b>
            </span>
          </v-sheet>
        </v-row>
      </v-card-title>
      <v-card-text>
        <!-- <pre>{{tests}}</pre> -->
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <div class="black--text">
            <b>{{ $t("Checkout") }}</b>
          </div>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="d-flex flex-row justify align-center mt-5 px-0 py-0">
          <!-- <pre>
                {{ candidates.length}}
            </pre> -->
             <v-sheet height="100%" width="100%" >
          <v-simple-table
            dense
            fixed-header
            :height="tests.length>6?'250':''"
          >
            <template>
              <thead>
                <tr>
                  <th class="text-left black--text" style="width: 200px">
                    {{ $t("tests") }}
                  </th>
                  <th class="text-left black--text" style="width: 100px">
                    {{ $t("Candidates") }}
                  </th>
                  <th class="text-left black--text">{{ $t("pUnit") }}</th>
                  <th class="text-left black--text">{{ $t("pTotal") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="test in tests" :key="test">
                  <td>{{ test.title }}</td>
                  <td>{{ candidates.length }}</td>
                  <td>{{ currencyFormatter(test.price) }}</td>
                  <td>{{ getSubTotal(candidates.length, test.price) }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
             </v-sheet>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <b class="black--text">{{ $t("notes") }}</b>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <p class="black--text">
            {{ $t("paymentNote") }}
          </p>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <strong class="black--text">{{ $t("bankDetails") }}</strong>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-0">
          <v-sheet class="" color="grey lighten-3" min-width="200">
            <div class="pa-3">
              <span class="body-2 text--black"
                >{{ $t("account") }}: 615456756 </span
              ><br />
              <span class="body-2 text--black">NIB: 000100000061545675657</span
              ><br />
              <span class="body-2 text--black"
                >{{ $t("bank") }}: MIllennium bim
              </span>
            </div>
          </v-sheet>
          <v-spacer></v-spacer>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-0 py-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" class="mx-2" @click="$emit('close')" text>
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" @click="$emit('sendTest')">
            {{ $t("finalize_payment") }}
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PaymentTest",
  props: {
    dialog: Boolean,
    tests: Array,
    candidates: Array,
  },
  data() {
    return {
      desserts: [
        {
          name: "Testes",
          calories: 159,
          pUnit: 300,
          pTotal: 40000,
        },
        {
          name: "Candidatos",
          calories: 237,
          pUnit: 300,
          pTotal: 40000,
        },
      ],
    };
  },
  methods: {
    getSubTotal(qt, price) {
      return this.currencyFormatter(qt * price);
    },
    currencyFormatter(price) {
      const locale = "pt-MZ";
      const currency = "MZN";
      let mtnLocale = Intl.NumberFormat(locale, {
        style: "currency",
        currency,
      });
      return mtnLocale.format(price).replace("n", "");
    },
    getTotalPrice() {
      var total = 0;
      var subtotal = 0;
      this.tests.forEach((element) => {
        subtotal = element.price * this.candidates.length;
        total += subtotal;
      });
      return this.currencyFormatter(total);
    },
    
  },
};
</script>
<style lang="css" scoped>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(50.6, 50.6, 50.6, 0.09);
  border: none;
}
table{
  max-height:20px
}

/* .theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0.6, 0.6, 0.6, 0.09);
  border: none;
} */
</style>