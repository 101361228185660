import gql from 'graphql-tag'
// import entityFragment from './../../setting/graphql/EntityFragment'

export default gql`{
  id
  address
  email
  name
  otherInfo
  telephone
}`