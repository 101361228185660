<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card class="pb-10">
      <v-system-bar
        window
        color="white"
      >
        <v-icon @click="$emit('close')">mdi-close</v-icon>
        <v-spacer></v-spacer>
       
      </v-system-bar>
      <v-container>
        <v-row class="d-flex flex-column justify-center align-center">
          <v-avatar
            size="100"
            outlined
            class="avatar-icon"
          >
            <v-icon
              dark
              large
              color="primary"
            >{{ data.icon }}</v-icon>
          </v-avatar>

          <div class="title black--text">{{ data.title }}</div>
          <blockquote class="  font-weight-small  black--text text-center px-10 mb-5 py-6">
            {{ data.content }}
          </blockquote>

          <v-btn
            color="primary"
            @click="$emit('close')"
          >
            <span class="white--text">Ok</span>
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'InfoViewDialog',
  props: {
    data: Object,
    dialog: Boolean
  }
}
</script>