import { render, staticRenderFns } from "./ReviewTestList.vue?vue&type=template&id=a6406ece"
import script from "./ReviewTestList.vue?vue&type=script&lang=js"
export * from "./ReviewTestList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports