import gql from 'graphql-tag'

export default gql`{
  id
  description
  minimumProfiles
  name
  permissions {
    id
    name
  }
  pricePerProfile
  pricePerUser
  profileInterval
  userInterval
  status
}`