<template>
  <v-dialog v-model="dialog1" scrollable max-width="350px" persistent>
    <v-card>
      <!-- ===={{ candidateId }}
      <pre>{{list}}</pre> -->
      <v-card-title>
        {{ $t("saveto") }}...
        <v-spacer></v-spacer>
        <v-icon
          @click="
            $emit('close');
            $emit('updateList');
          "
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 350px;">
        <template v-for="list in lists">
          <div
            class="d-flex flex-row justify-space-between align-center"
            :key="list.id"
          >
            <!-- <pre>
              {{ list }}
            </pre> -->
            <div>
              <v-checkbox
                v-model="selected"
                :label="list.name"
                :value="list.id"
                hide-details
                @change="addCandidateList(list.id)"
                :disabled="list.enable"
              ></v-checkbox>
            </div>
            <v-spacer></v-spacer>
            <div class="mt-5">
              <v-icon
                :disabled="list.enable"
                color="error"
                @click="openConfirmeDialog(list.id)"
                >mdi-close-circle</v-icon
              >
            </div>
          </div>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-show="!create" block text @click="create = true">
          <v-icon>mdi-plus</v-icon>
          <span class="text-lowercase">{{ $t("createlist") }}</span>
        </v-btn>
        <v-row v-show="create">
          <v-col cols="12" md="12">
            <v-text-field
              v-model="name"
              :label="$t('form_candidate_name')"
              clearable
              :placeholder="$t('insertNamelist')"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12" class="text-end">
            <v-btn text color="primary" @click="createList()">
              {{ $t("create") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>

      <confirm-dialog
        :dialog="confirm"
        :id="removeId"
        :question="$t('removeItemQ')"
        @yes="deleteList"
        @no="confirm = false"
      />

      <error-dialog
        :dialog="showError"
        :message="error"
        @close="showError = false"
      />
    </v-card>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          {{ $t("close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import {
  ADD_CANDIDATE_TO_VACANCY_LIST_MUTATION,
  CREATE_VACANCY_LIST_MUTATION,
  DELETE_VACANCY_LIST_MUTATION,
  REMOVE_CANDIDATE_FROM_VACANCY_LIST_MUTATION,
} from "../graphql/Mutation.resolver";
import { GET_VACANCY_LIST_QUERY } from "../graphql/Query.resolver";
import dialogMixins from "@/mixins/dialog";
import { formatError } from "@/utils";
import ConfirmDialog from "./../../../components/ConfirmDialog.vue";
import ErrorDialog from "./../../../components/ErrorDialog.vue";
export default {
  name: "AddCandidateListDialog",
  components: { ConfirmDialog, ErrorDialog },
  props: {
    candidateId: String,
    dialog1: Boolean,
    vacancyId: String,
    list: Array,
  },
  mixins: [dialogMixins],
  data: () => ({
    create: false,
    lastList: null,
    lists: [],
    name: "",
    selected: [],
    snackbar: false,
    skipQuery: true,
    text: "Adicionado a ",
    timeout: 2000,
  }),
  apollo: {
    lists: {
      query: GET_VACANCY_LIST_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          id: this.vacancyId,
        };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.selected = [];
          this.list.forEach((l) => {
            const index = l.candidates
              ? l.candidates.findIndex((c) => c == this.candidateId)
              : -1;
            if (index > -1) {
              this.selected.push(l.id);
            }
          });
          const uniqueNames = {};
          this.lists = data.lists
            .reduce((uniqueList, fav) => {
              if (!uniqueNames[fav.name]) {
                uniqueNames[fav.name] = true;
                uniqueList.push({ ...fav, name: this.$t(fav.name) });
              }
              return uniqueList;
            }, [])
            .concat(this.list);
          //   return {...l,name:this.$t(l.name)}
          // this.lists = data.lists.map(l=>{
          // })
        }
      },
    },
  },
  watch: {
    dialog1() {
      this.setInit();
    },
  },
  methods: {
    async addCandidateList(id) {
      const selectedIndex = this.selected.findIndex((l) => l == id);
      if (selectedIndex > -1) {
        try {
          const { data } = await this.$apollo.mutate({
            mutation: ADD_CANDIDATE_TO_VACANCY_LIST_MUTATION,
            variables: {
              id: this.vacancyId,
              listId: id,
              candidateId: this.candidateId,
            },
          });

          const index = this.lists.findIndex((l) => l.id == id);
          // this.lists.splice(index, 1, data.addCandidateList)
          this.lists[index].candidates =
            data.addCandidateToVacancyList.candidates;
          this.lastList = id;
          this.text = `${this.$t("candidate_add_fav")} ${
            data.addCandidateToVacancyList.name
          }`;
          this.snackbar = true;
        } catch (error) {
          this.error = formatError(error.message);
          this.showError = true;
        }
        this.$emit("updateList", true);
      } else {
        await this.removeCandidate(id);
      }
    },
    async createList() {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_VACANCY_LIST_MUTATION,
          variables: {
            id: this.vacancyId,
            candidateId: this.candidateId,
            name: this.name,
          },
        });
        this.selected.push(data.createVacancyList.id);
        this.lists.push(data.createVacancyList);
        this.name = "";
        this.create = false;
        this.text = `${this.$t("candidate_add_fav")} ${
          data.createVacancyList.name
        }`;
        this.lastList = data.createVacancyList.id;
        this.snackbar = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async deleteList(id) {
      try {
        this.confirm = false;
        await this.$apollo.mutate({
          mutation: DELETE_VACANCY_LIST_MUTATION,
          variables: { id: this.vacancyId, listId: id },
        });
        const selectedInex = this.selected.findIndex((s) => s == id);
        this.selected.splice(selectedInex, 1);
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists.splice(index, 1);
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async fetchLists() {
      this.$apollo.queries.lists.skip = false;
      await this.$apollo.queries.lists.refetch();
    },
    openConfirmeDialog(id) {
      this.removeId = id;
      this.confirm = true;
    },
    async removeCandidate(id) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: REMOVE_CANDIDATE_FROM_VACANCY_LIST_MUTATION,
          variables: {
            id: this.vacancyId,
            listId: id,
            candidateId: this.candidateId,
          },
        });
        const index = this.lists.findIndex((l) => l.id == id);
        this.lists.splice(index, 1, data.removeCandidateFromVacancyList);
        this.lastList = id;
        this.text = `${this.$t("candidate_remove_fav")} ${
          this.lists[index].name
        }`;
        this.snackbar = true;
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },
    async setInit() {
      await this.fetchLists();
      this.lists.forEach((l) => {
        const index = l.candidates
          ? l.candidates.findIndex((c) => c == this.candidateId)
          : -1;
        if (index > -1) {
          this.selected.push(l.id);
        }
      });
    },
  },
  beforeDestroy() {
    this.$emit("updateList");
    this.$emit("close");
  },
};
</script>
