import gql from 'graphql-tag'
import chatFragment from './ChatFragment'

export const CHATS_QUERY = gql `
  query getChatMessage($room: String) {
    chats: getChatMessage(room: $room) {
      ...${chatFragment}
    }
  }
`

export const CHATS_ROOM_QUERY = gql `
  query getReciveMessageEntity {
    recivedChats: getReciveMessageEntity {
      room :name
      testID: test
      flag
      messages{
        body
        time
        read
        date
        flag_to
        
        author {
          id
          name
          photo
          type
        }

        to{
          id
          name
          photo
          type
        }
      }
    }
  }
`

export const CHECK_CHAT_SUPORT = gql `
  query checkSuportEntity {
     checkSuportEntity {
      flag
    }
  }
`