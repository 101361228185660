<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pa-10">
      <!-- <pre>{{ vacancy }}</pre> -->
      <!-- {{ stage }} -->
      <v-row class="d-flex flex-column">
        <div class="text-center">
          {{ $t("send_reject_personalized_message") }}
        </div>
        <div class="mt-10">
          <tiptap-vuetify
            v-model="cancellationReason"
            :extensions="extensions"
            placeholder
            class="mb-2"
            style="max-width: 100%"
          />
        </div>
        <div class="text-right">
          <v-btn text small color="primary" @click="$emit('close')">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary" @click="feedback('reprove')">
            {{ $t("send") }}
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
const { format } = require("date-fns");
const { pt: ptPT, enUS } = require("date-fns/locale");
import { UPDATE_STATUS_STAGE_MUTATION } from "../graphql/Mutation.resolver";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "RejectCandidateDialog",
  props: {
    dialog: Boolean,
    selectedCandidates: Array,
    vacancyId: String,
    client: String,
    stage: String,
    vacancy: Object,
  },
  components: {
    TiptapVuetify,
    ProgressDialog,
  },

  data: () => ({
    dialog: true,
    status: "rejected",
    cancellationReason: ">>",
    interviewId: null,
    candidate: null,
    isLoading: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),
  watch: {
    vacancy: function(val) {
      if (val) {
        this.cancellationReason = this.$t("send_rejected_stage_email", {
          vacancy: val.title ? val.title : "",
          entity: val.entity.name ? val.entity.name : "",
        });
      }
    },
  },
  mounted() {
    if (this.vacancy) {
      this.cancellationReason = this.$t("send_rejected_stage_email", {
        vacancy: this.vacancy.title,
        entity: this.vacancy.entity.name,
      });
    }
  },

  computed: {},

  methods: {
    async feedback(status) {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_STAGE_MUTATION,
          variables: {
            vacancyId: this.vacancy.id,
            stageName: this.stage,
            candidates: this.selectedCandidates.map((candidate) => {
              return candidate.id;
            }),
            status: status,
            message: this.cancellationReason,
          },
        });
        this.$emit("fetch");
        this.$emit("close");
        let successMsg = this.$t("candidate_stage_approved_successfully");
        if (status === "reprove") {
          successMsg = this.$t("candidate_was_notified_by_email");
        }
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    localDate(dateTime) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: ptPT,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>
