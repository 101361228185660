<template>
  <v-dialog v-model="dialog" persistent :max-width="dialogWidth" class="scroll">
    <v-card class="px-5 pb-8 scroll">
      <v-container fluid v-if="page === 1">
        <v-row class="d-flex align-center py-4">
          <div>
            <v-radio-group v-model="filter.type" row @change="applyFilter()">
              <v-radio :label="$t('technician')" value="technician"></v-radio>
              <v-radio
                :label="$t('psychological')"
                value="psychological"
              ></v-radio>
            </v-radio-group>
          </div>
          <div>
            <v-checkbox
              v-model="filter.user"
              :label="$t('mytest')"
              :value="currentUser.id"
              @change="applyFilter()"
            ></v-checkbox>
          </div>
          <v-spacer></v-spacer>
          <div class="px-4">
            <test-filter @apply="applyTestFilter" @clean="applyTestFilter" />
          </div>
          <div>
            <v-text-field
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field>
          </div>
        </v-row>
        <v-divider></v-divider>

        <infinite-scroll
          :items="filteredItems"
          :totalPages="totalPages"
          @refetch="fetch"
        >
          <template v-slot:item="{ item }">
            <v-skeleton-loader
              v-if="$apollo.loading"
              elevation="2"
              v-bind="filteredItems"
              type="image, article"
            ></v-skeleton-loader>
            <test-card
              v-else
              :selectedTests="selectedTests"
              :select="true"
              :test="item"
              @preview="showPreviewDialog"
              @select="selectTest"
            />
          </template>
        </infinite-scroll>
        <!-- <v-row class="mx-4 mt-0 scroll-test">
          <v-col cols="12" md="4" xl="3" v-for="test in getTests" :key="test.title">
            <test-card :selectedTests="selectedTests" :select="true" :test="test" @select="selectTest" />
          </v-col>
        </v-row> -->
      </v-container>
      <v-row v-else justify="center" class="pt-8">
        <!-- <pre>
          {{vacancyId}}
        </pre> -->
        <v-col cols="12" md="12">
          <!-- <pre>{{ select.id ? select.id : select }}</pre> -->
          <v-row v-if="!vacancyId">
            <v-col cols="12">
              <v-combobox
                v-model="select"
                :items="vacancies"
                item-text="title"
                item-value="id"
                :label="$t('Job_title')"
                outlined
                dense
              ></v-combobox>
            </v-col>
          </v-row>
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="date"
                :label="$t('date_limit_test')"
                prepend-inner-icon="event"
                readonly
                v-on="on"
                dense
                outlined
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              scrollable
              :min="dateFormat()"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false">{{
                $t("cancel")
              }}</v-btn>
              <v-btn text color="primary" @click="$refs.menuDate.save(date)"
                >OK</v-btn
              >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12">
          <v-text-field
            v-model="time"
            :label="$t('time_limit_test')"
            v-mask="'##:##'"
            placeholder="00:00"
            prepend-inner-icon="access_time"
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row class="d-flex justify-end align-center py-0 px-2">
        <v-btn text @click="closeDialog()" color="primary">{{
          $t("cancel")
        }}</v-btn>
        <v-btn
          color="primary"
          @click="page === 1 ? nextStep() : openClosePayment()"
          ><v-icon left v-show="false">mdi-send</v-icon>
          {{ buttonsTitle }}</v-btn
        >
      </v-row>
    </v-card>
    <payment
      :dialog="showPayment"
      :tests="selectedTests"
      :candidates="selectedCandidates"
      @close="openClosePayment()"
      @sendTest="sendTestPayment()"
    />
    <preview-test-dialog
      :dialog="previewDialog"
      :test="test"
      @close="previewDialog = false"
    />

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="showError = false"
    />
    <progress-dialog :processing="isLoading" />
    <success-dialog
      :dialog="showSuccess"
      :message="success"
      @close="showSuccess = false"
    />
  </v-dialog>
</template>

<script>
import {
  SEND_PAY_TEST_MUTATION,
  SEND_TEST_MUTATION,
} from "../graphql/Mutation.resolver";
import { GET_TESTS_ENTITY_QUERY } from "../../test/graphql/Query.service";
import { mapActions, mapGetters } from "vuex";
// import { formatError } from '@/utils'
import InfiniteScroll from "./InfiniteScroll.vue";
import moment from "moment";
import PreviewTestDialog from "./../../test/components/PreviewTestDialog.vue";
import TestFilter from "../../../components/TestFilter.vue";
import payment from "../../../components/paymentTest.vue";
import TestCard from "../../test/components/TestCard.vue";
import ErrorDialog from "../../../components/ErrorDialog.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
import Test from "@/models/Test";
import { GET_VACANCIES_ENTITY_QUERY } from "../graphql/Query.resolver";
export default {
  name: "SelectTest",
  components: {
    PreviewTestDialog,
    ErrorDialog,
    InfiniteScroll,
    ProgressDialog,
    SuccessDialog,
    TestCard,
    TestFilter,
    payment,
  },
  mixins: [ADD_DIALOG, replaceSpecialCharsMixins],
  props: {
    dialog: Boolean,
    selectedCandidates: Array,
    vacancyId: String,
    // vacancies: Array,
  },
  data: () => ({
    select: [],
    vacancies: [],
    appliedFilter: true,
    date: null,
    filter: {
      areas: undefined,
      category: "",
      entity: undefined,
      limit: 4,
      page: 1,
      positions: undefined,
      type: "",
    },
    menuDate: false,
    menuTime: false,
    page: 1,
    previewDialog: false,
    selectedTests: [],
    test: new Test(),
    tests: [],
    textSearch: undefined,
    showPayment: false,
    time: null,
    totalPages: 1,
  }),

  apollo: {
    tests: {
      query: GET_TESTS_ENTITY_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
      fetchPolicy: "no-cache",
    },
    vacancies: {
      query: GET_VACANCIES_ENTITY_QUERY,
      variables() {
        return { filterTest: this.filter };
      },
      fetchPolicy: "no-cache",
    },
  },

  watch: {
    tests: function(val) {
      if (this.appliedFilter) {
        this.setTestsState(val.tests);
        this.appliedFilter = false;
      } else {
        this.setTestsScrolled(val.tests);
      }
      this.totalPages = val.totalPages;
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getTests: "test/getTests",
    }),
    buttonsTitle() {
      return this.page === 1 ? this.$t("send_test") : this.$t("submit_test");
    },
    dialogWidth() {
      return this.page === 1 ? "70%" : "30%";
    },
    filteredItems() {
      let resultItems = [...this.getTests];
      resultItems = resultItems.filter(
        (test) =>
          !(
            test.entity &&
            test.creatorType === "partner" &&
            !test.status &&
            test.reason != null
          ) &&
          test.user != null &&
          !test.isDraft
      );

      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      return resultItems;
    },
  },

  methods: {
    ...mapActions({
      removeTestState: "test/removeTest",
      setTestsState: "test/setTests",
      setTestsScrolled: "test/setTestsScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch(1);
    },
    applyTestFilter(filter) {
      this.filter = { ...filter };
      this.applyFilter();
    },
    changePage(page) {
      this.filter.page = page;
      this.applyFilter();
    },
    cleanFilter() {
      this.filter = {
        areas: undefined,
        category: undefined,
        positions: undefined,
      };
    },
    closeDialog() {
      this.page = 1;
      this.selectedTests = [];
      this.$emit("close");
      this.icon = !this.icon;
    },
    closeDialogDone(candidacies) {
      this.page = 1;
      this.selectedTests = [];
      this.$emit("done", candidacies);
      this.icon = !this.icon;
    },
    dateFormat() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
    async fetch(page) {
      console.log(page);
      if (page > this.totalPages) {
        return;
      }
      this.filter.page = page;
      await this.$apollo.queries.tests.refetch();
    },
    nextStep() {
      this.page = 2;
    },
    selectTest(test) {
      const index = this.selectedTests.findIndex((t) => t.id == test.id);
      if (index > -1) {
        this.selectedTests.splice(index, 1);
      } else {
        // delete test.user;
        this.selectedTests.push(
          Object.assign({}, { ...test, user: test.user })
        );
      }
    },
    openClosePayment() {
      console.log("what1?");
      // console.log(this.formatNoPriceTest().length);
      if (this.getTotalPrice() > 0) {
        console.log("what?");
        if (this.selectedTests.length) {
          this.showPayment = !this.showPayment;
          this.dialog = !this.dialog;
        }
      } else {
        console.log("what2?");
        // if (this.formatNoPriceTest().length > 0) {
        console.log("what3?");
        this.sendTest(1);
        // }
      }
    },
    async sendTestPayment() {
      console.log(this.select);
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SEND_PAY_TEST_MUTATION,
          variables: {
            candidates: this.formatCandidateID(),
            date: this.date,
            tests: this.formatTestID(),
            time: this.time,
            vacancyId: this.vacancyId ? this.vacancyId : this.select.id,
            vacancyTitle: this.select.length === 0 ? "" : this.select,
            amount: this.getTotalPrice(),
          },
        });
        if (this.formatNoPriceTest().length > 0) {
          this.sendTest(0);
        }
        this.success = this.$t("send_test_success_pay");
        this.showSuccess = true;
        this.closeDialogDone();
        console.log(data);
      } catch (error) {
        console.log(error);
        this.error = error.message;
        this.showError = true;
      } finally {
        this.isLoading = false;
        this.showPayment = false;
      }
    },
    async sendTest(pay) {
      console.log("Aqui acontece!");
      this.isLoading = true;
      try {
        const { data } = await this.$apollo.mutate({
          mutation: SEND_TEST_MUTATION,
          variables: {
            candidates: this.formatCandidates(),
            date: this.date,
            tests: this.formatNoPriceTest(),
            time: this.time,
            vacancyId: this.vacancyId ? this.vacancyId : this.select.id,
            entity: this.currentUser.entity.id,
          },
        });
        let textError = data.sendTests.error
          ? JSON.parse(data.sendTests.error)
          : undefined;
        let errorMessages = "";
        if (textError) {
          textError.forEach((element) => {
            errorMessages = `${errorMessages} ${this.$t("send_test_err")
              .replace("test_title", `${element.test},`)
              .replace(" candidates", element.candidates)} <br />`;
          });
        }
        if (this.getTotalPrice() === 0 && pay === 1) {
          this.success = `Teste enviado com sucesso! <br />Os candidatos foram notificados por email <br />${textError}`;
          this.success = `${this.$t("send_test_success")} ${errorMessages}`;
          this.showSuccess = true;
          this.closeDialogDone();
        }
      } catch (error) {
        const obj = JSON.parse(error.message.split("GraphQL error: ")[1]);
        let messages = "";
        obj.forEach((element) => {
          messages = `${messages} ${this.$t("send_test_err")
            .replace("test_title", `${element.test},`)
            .replace(" candidates", element.candidates)} <br />`;
        });
        this.error = messages;
        this.showError = true;
      } finally {
        this.isLoading = false;
      }
    },
    showPreviewDialog(test) {
      this.test = test;
      this.previewDialog = true;
    },
    formatCandidates() {
      return this.selectedCandidates.map((c) => {
        delete c.age;
        delete c.certificates;
        delete c.entities;
        delete c.fullName;
        delete c.notes;
        delete c.blocked;
        delete c.belongsToMany;
        return c;
      });
    },
    formatNoPriceTest() {
      var test = [];
      this.selectedTests.forEach((t) => {
        // if (t.price == 0) {
        delete t.user;
        test.push(t);
        // }
      });
      return test;
      // return this.selectedTests;
    },
    formatCandidateID() {
      var arrayCandidate = [];
      this.selectedCandidates.forEach((element) => {
        arrayCandidate.push(element.id);
      });
      return arrayCandidate;
    },

    formatTestID() {
      var arrayTest = [];
      this.selectedTests.forEach((element) => {
        console.log("element", element);
        if (element.user != null && element.price != 0) {
          arrayTest.push({ test: element.id, partner: element.user.id });
        }
      });

      // console.log('input_test_partner',arrayTest);
      return arrayTest;
    },

    getTotalPrice() {
      var total = 0;
      var subtotal = 0;
      this.selectedTests.forEach((element) => {
        subtotal = element.price * this.selectedCandidates.length;
        total += subtotal;
      });
      return total;
    },
  },
};
</script>

<style scoped>
.scroll-test {
  height: 450px;
  overflow: auto;
}
</style>
